import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import config from "../config/config";
import moment from "moment";
import id from "../assets/images/id.svg";
import income from "../assets/images/income.png";
import level from "../assets/images/level.svg";
import roi from "../assets/images/roi.svg";
import refferal from "../assets/images/refferal.svg";
import investment from "../assets/images/investment.svg";
import total from "../assets/images/total.svg";
import available from "../assets/images/available.svg";
import link from "../assets/images/link.svg";
import copy from "../assets/images/copy.svg";
import date from "../assets/images/date.svg";
import user from "../assets/images/user.svg";
import plan from "../assets/images/plan.svg";
import Web3 from "web3";
import syncEach from "sync-each";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext
} from "ethereum-multicall";
import mlmabi from "../ABI/mlmABI";
import usdtabi from "../ABI/usdtABI";
import BigNumber from "bignumber.js";
import { type WalletClient, useWalletClient } from "wagmi";

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState("tab1");
  let chainid = config.networkVersion;
  const { data: walletClient } = useWalletClient({ chainid });
  const [userdata, setdata] = useState({});
  const [userinfo, setuserinfo] = useState({});
  const [progress, setprogress] = useState("false");
  const [claimprogress, setclaimprogress] = useState("false");
  const [referrallist, setreferrallist] = useState([]);
  const [roiAmount, setRoi] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [userRef, setUserRef] = useState([]);
  const [missedIncome, setMissedIncome] = useState([]);
  const [roiBal1, setRoiBal1] = useState([]);
  const [roiBal2, setRoiBal2] = useState([]);
  const [levelX, setLevelX] = useState([]);
  const [topUser1, settopUser1] = useState([]);
  const [topUser2, settopUser2] = useState([]);
  const [maxIncome, setmaxIncome] = useState([]);
  const [userinvest, setuserinvest] = useState([]);
  const [userincome, setuserincome] = useState([]);
  const [expStatus, setExpStatus] = useState(false);
  const [gainMis, setGainMis] = useState([]);
  const [tempIncome, setTempIncome] = useState([]);
  const [levelInc, setLevelInc] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const columns = [
    {
      name: "ID",
      className: "answer",
      align: "left",
      sortable: false,
      cell: (record) => {
        let text = " ID " + record.id;
        return text;
      }
    },
    {
      name: "Date of Join",
      className: "answer",
      align: "left",
      sortable: false,
      cell: (record) => {
        var convert = moment
          .unix(record.joinedDate)
          .format("DD/MM/YYYY HH:mm a");
        return convert;
      }
    }
  ];

  const data = [
    {
      id: 1,
      date: "2023-00-00, 00:00 AM",
      JoinedAmount: "ID 1234"
    },
    {
      id: 2,
      date: "2023-00-00, 00:00 AM",
      JoinedAmount: "ID 1234"
    },
    {
      id: 3,
      date: "2023-00-00, 00:00 AM",
      JoinedAmount: "ID 1234"
    },
    {
      id: 4,
      date: "2023-00-00, 00:00 AM",
      JoinedAmount: "ID 1234"
    }
  ];

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 5) + "...." + addr.slice(addr.length - 4);
    } else {
      return "";
    }
  };

  useEffect(() => {
    getdetails();
  }, [walletClient]);

  async function getdetails() {
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
      if (datas.userdetails && datas.userdetails.isExist) {
        getuserdetails(datas);
        setuserinfo(datas.userdetails);
        // triggerFunctionWithDelay(datas);
        console.log(datas.userdetails, "======conconcjcc");
      } else {
        window.location.href = "/join-now";
      }
    } else {
      //window.location.href = "/";
    }
  }

  // const triggerFunctionWithDelay = async (record) => {
  //   if (record) {
  //     let web3 = new Web3(
  //       record &&
  //       record.provider &&
  //       record.provider != null &&
  //       record.provider != undefined &&
  //       record.provider != ""
  //         ? record.provider
  //         : window.ethereum
  //     );
  //     const delayInSeconds = 60; // Example: 60 seconds delay
  //     var mlmContract = new web3.eth.Contract(mlmabi, config.mlmcontract);
  //     setTimeout(async () => {
  //       let valuesRoi = await mlmContract.methods
  //         .checkRoi(record.address)
  //         .call();
  //       console.log(valuesRoi[0], "valuesRoi", valuesRoi[1]);
  //       setRoiBal1(valuesRoi[0]);
  //       console.log("Contract function executed after delay");
  //     }, delayInSeconds * 1000);
  //   }
  // };

  async function getuserdetails(record) {
    let web3 = new Web3(
      record &&
      record.provider &&
      record.provider != null &&
      record.provider != undefined &&
      record.provider != ""
        ? record.provider
        : window.ethereum
    );
    var mlmContract = new web3.eth.Contract(mlmabi, config.mlmcontract);
    let arrlen = await mlmContract.methods
      .viewUserRefaral(record.address)
      .call();
    setUserRef(arrlen.length);
    let roiDetails = await mlmContract.methods.checkRoi(record.address).call();
    console.log(roiDetails[0], "roiDetailsroiDetailsroiDetails");
    setRoi(roiDetails[0]);
    let refuserarray = [];
    syncEach(
      arrlen,
      async function (list, next) {
        console.log(list, "saran");
        let refuser = await mlmContract.methods.users(list).call();
        refuserarray.push(refuser);
        next();
      },
      function (err, transformedItems) {
        setreferrallist(refuserarray);
        console.log(refuserarray, "====refuserarrayrefuserarray");
      }
    );
    let poolLength = await mlmContract.methods.getPoolLenght().call();
    const dataArray = [];

    for (var i = 0; i < poolLength; i++) {
      try {
        let result = await mlmContract.methods.poolInfo(i).call();
        dataArray.push(result);
      } catch (error) {
        console.error(`Error fetching data for index ${i}:`, error);
      }
    }
    setPlanList(dataArray);
    let missedIncome = await mlmContract.methods
      .missedIncome(record.address)
      .call();
    setMissedIncome(missedIncome);

    let valuesRoi = await mlmContract.methods.checkRoi(record.address).call();
    console.log(valuesRoi[0], "valuesRoi", valuesRoi[1]);
    setRoiBal1(valuesRoi[0]);
    setRoiBal2(valuesRoi[1]);
    let expirystatus = await mlmContract.methods
      .expiryStatus(record.address)
      .call();
    console.log(expirystatus, "expirystatus");
    setExpStatus(expirystatus);
    let MacIncome = await mlmContract.methods
      .checkMaxIcome(record.address)
      .call();
    setmaxIncome(MacIncome);
    let userInvest = await mlmContract.methods
      .investAmountRoi(record.address)
      .call();
    setuserinvest(userInvest);

    let userIncome = await mlmContract.methods
      .userIncome(record.address)
      .call();

    setuserincome(userIncome);
    setLevelX(MacIncome / userInvest);

    let topUsr1 = await mlmContract.methods.topuser().call();
    let topUsr2 = await mlmContract.methods.topuser1().call();
    settopUser1(topUsr1);
    settopUser2(topUsr2);

    let gainMis = await mlmContract.methods
      .gaintempIncome(record.address)
      .call();
    console.log(gainMis, "gainMis");
    setGainMis(gainMis);

    let tempIn = await mlmContract.methods.tempIncome(record.address).call();
    console.log(tempIn, "tempIn");
    setTempIncome(tempIn);

    let sumValue = 0;
    for (let j = 1; j <= 12; j++) {
      try {
        let result = await mlmContract.methods
          .userLevelData(record.address, i.toString())
          .call();
        console.log(result.rewardAmount, "efdeawfcedfc");
        sumValue += Number(result.rewardAmount);
      } catch (error) {
        console.error(`Error fetching data for index ${i}:`, error);
      }
    }
    console.log(sumValue, "sumValue");
    setLevelInc(sumValue);
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText =
      config.Front_URL + "join-now/" + localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function purchaselevel(pool) {
    try {
      setprogress("true");
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          var usdtContract = new web3.eth.Contract(
            usdtabi,
            config.usdtcontract
          );
          var mlmContract = new web3.eth.Contract(mlmabi, config.mlmcontract);

          let roiamount = await mlmContract.methods
            .checkRoi(datas.address)
            .call();

          if (
            parseFloat(userincome) + parseFloat(roiamount[0]) >=
              parseFloat(maxIncome) &&
            pool != 0
          ) {
            setprogress("false");
            toastAlert("error", "Plan Expired", "network");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            return false;
          }

          var tokenbalance = await usdtContract.methods
            .balanceOf(datas.address)
            .call();
          let poolinfo = await mlmContract.methods.poolInfo(pool).call();
          if (parseFloat(tokenbalance) >= parseFloat(poolinfo.poolAmount)) {
            let allowedamt = await convert(poolinfo.poolAmount);

            let approvedval = await usdtContract.methods
              .allowance(datas.address, config.mlmcontract)
              .call();

            let bnbBal = await web3.eth.getBalance(datas.address);
            bnbBal = bnbBal / 1e18;
            console.log(bnbBal, "bnbBal");

            if (parseFloat(approvedval) < parseFloat(poolinfo.poolAmount)) {
              let estimateGas = await usdtContract.methods
                .approve(config.mlmcontract, allowedamt.toString())
                .estimateGas({ from: datas.address })
                .catch((err) => console.log(err));
              let gasPricebusd = await web3.eth.getGasPrice();
              console.log(estimateGas * gasPricebusd, "estimateGas");
              let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
              console.log(
                estimateGasFee,
                bnbBal,
                estimateGasFee > bnbBal,
                "edwfced"
              );

              if (estimateGasFee > bnbBal) {
                setprogress("false");
                toastAlert("error", "BNB Balance is Low", "network");
                return false;
              }
            } else {
              let estimateGas = await mlmContract.methods
                .rejoin(pool)
                .estimateGas({ from: datas.address })
                .catch((err) => console.log(err));
              let gasPricebusd = await web3.eth.getGasPrice();
              console.log(estimateGas * gasPricebusd, "estimateGas");
              let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
              console.log(
                estimateGasFee,
                bnbBal,
                estimateGasFee > bnbBal,
                "edwfced"
              );

              if (estimateGasFee > bnbBal) {
                setprogress("false");
                toastAlert("error", "BNB Balance is Low", "network");
                return false;
              }
            }

            var allowance =
              parseFloat(approvedval) < parseFloat(poolinfo.poolAmount)
                ? await usdtContract.methods
                    .approve(config.mlmcontract, allowedamt.toString())
                    .send({ from: datas.address })
                : true;
            if (allowance) {
              let register = await mlmContract.methods.rejoin(pool).send({
                from: datas.address
              });
              if (register && register.status && register.transactionHash) {
                setprogress("false");
                toastAlert("success", "Joined Successfully", "network");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
                getdetails();
              } else {
                setprogress("false");
                toastAlert("success", "Failed , try again later", "network");
              }
            } else {
              setprogress("false");
              toastAlert("success", "Failed , try again later", "network");
            }
          } else {
            setprogress("false");
            toastAlert("error", "USDT Balance is Low", "network");
          }
        } else {
          setprogress("false");
          toastAlert(
            "error",
            "Please Join First to proceed further",
            "network"
          );
          window.location.href = "/join-now";
        }
      } else {
        setprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      console.log(err, "=-===errrrrrorororrrrrr");
      setprogress("false");
    }
  }

  async function claimrewards() {
    try {
      setclaimprogress("true");
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );

          let bnbBal = await web3.eth.getBalance(datas.address);
          bnbBal = bnbBal / 1e18;
          console.log(bnbBal, "bnbBal");

          var mlmContract = new web3.eth.Contract(mlmabi, config.mlmcontract);

          let claimamt =
            parseFloat(userinfo.totalIncome) +
            parseFloat(roiBal1) -
            parseFloat(userinfo.totalWithdraw);
          console.log(claimamt, "claimamt");
          if (parseFloat(claimamt) >= 5 * 1e18) {
            let estimateGas = await mlmContract.methods
              .claimRewards()
              .estimateGas({ from: datas.address })
              .catch((err) => console.log(err));
            let gasPricebusd = await web3.eth.getGasPrice();
            console.log(estimateGas * gasPricebusd, "estimateGas");
            let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
            console.log(
              estimateGasFee,
              bnbBal,
              estimateGasFee > bnbBal,
              "edwfced"
            );

            if (estimateGasFee > bnbBal) {
              setclaimprogress("false");
              toastAlert("error", "BNB Balance is Low", "network");
              return false;
            }

            let claimed = await mlmContract.methods
              .claimRewards()
              .send({ from: datas.address });
            if (claimed && claimed.status && claimed.transactionHash) {
              setclaimprogress("false");
              toastAlert("success", "Claimed Successfully", "network");
              getdetails();
            } else {
              setclaimprogress("false");
              toastAlert("success", "Failed , try again later", "network");
            }
          } else {
            setclaimprogress("false");
            toastAlert(
              "error",
              "Minimum Claim amount should be 5 USDT",
              "network"
            );
          }
        } else {
          setclaimprogress("false");
          toastAlert(
            "error",
            "Please Join First to proceed further",
            "network"
          );
          window.location.href = "/join-now";
        }
      } else {
        setclaimprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      console.log(err, "=-===errrrrrorororrrrrr");
      toastAlert("error", "Claim Error", "network");
      setclaimprogress("false");
    }
  }

  async function empty() {}

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  return (
    <div>
      <Navbar />
      <div className="page_box pb-5">
        <div className="dashboard">
          <div className="container">
            <div className="head_flx mb-4">
              <h3 className="h3tag">Dashboard</h3>
              <div className="d-flex gap-2">
                <div className="box">
                  <img
                    src={require("../assets/images/parent.png")}
                    class="img-fluid cursor"
                    alt="img"
                  />
                  <p className="mb-0">
                    <span>Parent Address : </span>
                    {userinfo && userinfo.parent
                      ? userinfo.parent ==
                        0x0000000000000000000000000000000000000000
                        ? userdata.address
                        : userinfo.parent
                      : ""}
                  </p>
                </div>
                <div className="box">
                  <img src={date} class="img-fluid" alt="img" />
                  <p className="mb-0">
                    <span>Joining Date & Time : </span>
                    {userinfo && userinfo.joinedDate
                      ? moment
                          .unix(userinfo.joinedDate)
                          .format("DD/MM/YYYY HH:mm a")
                      : ""}
                  </p>
                </div>
                {/* {tempIncome / 1e18 > 0 && (
                  <div className="box">
                   
                    <p className="mb-0">
                      <span>Temp Income : </span>
                      {tempIncome / 1e18 > 0 ? tempIncome / 1e18 : 0}
                    </p>
                  </div>
                )} */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-xl-3">
                <div className="dashbox mb-4">
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={id} class="img-fluid" alt="img" />
                    </div>
                    <div>
                      <h5>
                        {userinfo && userinfo.userid ? userinfo.userid : ""}
                      </h5>
                      <p className="mb-0">My ID</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={income} class="img-fluid" alt="img" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-start justify-content-between gap-2">
                        <h5>
                          {userinfo && userinfo.directIncome
                            ? (
                                parseFloat(userinfo.directIncome) / 1e18
                              ).toFixed(4)
                            : "0"}
                        </h5>
                        <span>USDT</span>
                      </div>
                      <p className="mb-0">Direct Income</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={roi} class="img-fluid" alt="img" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-start justify-content-between gap-2">
                        <h5>
                          {userinfo &&
                          parseFloat(userinfo.roiIncome) +
                            parseFloat(roiBal1) +
                            parseFloat(levelInc) >
                            0
                            ? (
                                (parseFloat(userinfo.roiIncome) +
                                  parseFloat(roiBal1) +
                                  parseFloat(levelInc)) /
                                1e18
                              ).toFixed(4)
                            : 0}
                        </h5>
                        <span>USDT</span>
                      </div>
                      <p className="mb-0">ROR + Level ROR Income</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={roi} class="img-fluid" alt="img" />
                    </div>
                    <div>
                      <h5>
                        {parseFloat(roiBal2) + parseFloat(tempIncome) > 0 &&
                        !expStatus
                          ? (
                              (parseFloat(roiBal2) + parseFloat(tempIncome)) /
                              1e18
                            ).toFixed(4) //+TempIncome
                          : 0}
                      </h5>
                      <p className="mb-0">Claimable Missed Income</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={roi} class="img-fluid" alt="img" />
                    </div>
                    <div>
                      <h5>
                        {parseFloat(gainMis) > 0
                          ? (parseFloat(gainMis) / 1e18).toFixed(4)
                          : 0}
                      </h5>
                      <p className="mb-0">Gain Missed Income</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={refferal} class="img-fluid" alt="img" />
                    </div>
                    <div>
                      <h5>{userRef ? userRef : "0"}</h5>
                      <p className="mb-0">Referral Count</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={level} class="img-fluid" alt="img" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-start justify-content-between gap-2">
                        <h5>
                          {userinfo && userinfo.levelIncome
                            ? (parseFloat(userinfo.levelIncome) / 1e18).toFixed(
                                4
                              )
                            : "0"}
                        </h5>
                        <span>USDT</span>
                      </div>
                      <p className="mb-0">Income Level</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={income} class="img-fluid" alt="img" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-start justify-content-between gap-2">
                        <h5>
                          {userinfo && userinfo.withdrawlevelIncome
                            ? (
                                parseFloat(userinfo.withdrawlevelIncome) / 1e18
                              ).toFixed(4)
                            : "0"}
                        </h5>
                        <span>USDT</span>
                      </div>
                      <p className="mb-0">Withdraw level Income</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={level} class="img-fluid" alt="img" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-start justify-content-between gap-2">
                        <h5>
                          {missedIncome
                            ? (parseFloat(missedIncome) / 1e18).toFixed(4)
                            : "0"}
                        </h5>
                        <span>USDT</span>
                      </div>
                      <p className="mb-0">Missed Income</p>
                    </div>
                  </div>

                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={level} class="img-fluid" alt="img" />
                    </div>
                    <div className="w-100">
                      {console.log(
                        userdata.address,
                        "userdata.address",
                        topUser1,
                        topUser2
                      )}
                      <div className="d-flex align-items-start justify-content-between gap-2">
                        <h5>
                          {userdata.address == topUser1 ||
                          userdata.address == topUser2
                            ? 5
                            : levelX}
                          x
                        </h5>
                        {/* <span>USDT</span> */}
                      </div>
                      <p className="mb-0">Level Income</p>
                    </div>
                  </div>
                  <div className="box box_hover mb-4">
                    <div className="img_box">
                      <img src={refferal} class="img-fluid" alt="img" />
                    </div>
                    <div>
                      <h5>
                        {parseFloat(maxIncome) -
                          parseFloat(userincome) +
                          parseFloat(roiAmount) >
                          0 ||
                        userdata.address == topUser1 ||
                        userdata.address == topUser2
                          ? "Active"
                          : "Inactive"}
                      </h5>
                      <p className="mb-0">User Status</p>
                    </div>
                  </div>
                </div>
                <div className="dashbox mb-4">
                  <div className="box box_hover d-block affiliate">
                    <div className="d-flex align-items-center gap-2">
                      <img src={link} class="img-fluid" alt="img" />
                      <span>Affiliate Link</span>
                    </div>
                    <div className="link">
                      <p>
                        {config.Front_URL +
                          "join-now/" +
                          halfAddrShow(localStorage.getItem("account"))}
                      </p>
                      <img
                        src={copy}
                        class="img-fluid cursor"
                        alt="img"
                        onClick={copyToClipboard}
                      />
                    </div>
                    {/* <ul className="social" >
                      <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                      <li><a href="#"><i class="fa-brands fa-x-twitter"></i></a></li>
                      <li><a href="#"><i class="fab fa-telegram-plane"></i></a></li>
                      <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                      <li><a href="#"><i class="fas fa-phone-alt"></i></a></li>
                    </ul>*/}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-xl-9">
                <div className="dashbox mb-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="box">
                        <div className="img_box">
                          <img src={investment} class="img-fluid" alt="img" />
                        </div>
                        <div>
                          <p className="mb-0">Investment Amount</p>
                          <h5 className="mb-0 d-flex align-items-center gap-2">
                            {userinfo && userinfo.investAmount
                              ? (
                                  parseFloat(userinfo.investAmount) / 1e18
                                ).toFixed(4)
                              : "0"}
                            <span>USDT</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="box mt-4 mt-lg-0">
                        <div className="img_box">
                          <img src={total} class="img-fluid" alt="img" />
                        </div>
                        <div>
                          <p className="mb-0">Total Income</p>
                          <h5 className="mb-0 d-flex align-items-center gap-2">
                            {userinfo &&
                            parseFloat(userinfo.totalIncome) +
                              parseFloat(roiBal1) >
                              0
                              ? (
                                  (parseFloat(userinfo.totalIncome) +
                                    parseFloat(roiBal1)) /
                                  1e18
                                ).toFixed(4)
                              : "0"}
                            <span>USDT</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="box mt-4">
                        <div className="img_box">
                          <img
                            src={income}
                            class="img-fluid"
                            alt="img"
                            width={23}
                          />
                        </div>
                        <div>
                          <p className="mb-0"> Total Withdraw </p>
                          <h5 className="mb-0 d-flex align-items-center gap-2">
                            {userinfo && userinfo.totalWithdraw
                              ? (
                                  parseFloat(userinfo.totalWithdraw) / 1e18
                                ).toFixed(4)
                              : "0"}
                            <span>USDT</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="box mt-4">
                        <div className="img_box">
                          <img src={available} class="img-fluid" alt="img" />
                        </div>
                        <div className="d-flex align-items-start justify-content-between w-100 flex-wrap gap-2">
                          <div>
                            <p className="mb-0">Available Balance</p>
                            <h5 className="mb-0 d-flex align-items-center gap-2">
                              {userinfo && userinfo.totalIncome
                                ? (
                                    (parseFloat(userinfo.totalIncome) +
                                      parseFloat(roiBal1) -
                                      parseFloat(userinfo.totalWithdraw)) /
                                    1e18
                                  ).toFixed(4)
                                : "0"}
                              <span>USDT</span>
                            </h5>
                          </div>
                          <button
                            className="claim_btn"
                            type="button"
                            onClick={
                              claimprogress == "false"
                                ? () => claimrewards()
                                : () => empty()
                            }
                          >
                            {claimprogress == "false"
                              ? "Claim Now"
                              : "Processing.."}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashbox mb-4 plan">
                  <h4 className="h4tag mb-4">Plans</h4>
                  <div className="row">
                    {planList &&
                      planList.map((item, i) =>
                        userinfo &&
                        expStatus &&
                        userdata.address != topUser1 &&
                        userdata.address != topUser2 ? (
                          i === 0 ? (
                            <div className="col-xl-4 d-flex">
                              <div className="box mb-4 current">
                                <div className="img_box">
                                  <img src={plan} class="img-fluid" alt="img" />
                                </div>
                                <div>
                                  <p className="mb-0">Plan {i + 1}</p>
                                  <h5 className="mb-0">
                                    {item.poolAmount / 1e18}
                                  </h5>
                                  <button
                                    className="claim_btn"
                                    onClick={
                                      progress == "false"
                                        ? () => purchaselevel(i)
                                        : () => empty()
                                    }
                                  >
                                    {progress == "false"
                                      ? "Join Now"
                                      : "Processing.."}
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-xl-4 d-flex">
                              <div className="box mb-4 current">
                                <div className="img_box">
                                  <img src={plan} class="img-fluid" alt="img" />
                                </div>
                                <div>
                                  <p className="mb-0">Plan {i + 1}</p>
                                  <h5 className="mb-0">
                                    {item.poolAmount / 1e18} USDT
                                  </h5>
                                </div>
                              </div>
                            </div>
                          )
                        ) : i <= parseInt(userinfo.userCurrentPlan) ? (
                          <div className="col-xl-4 d-flex">
                            <div className="box mb-4 active">
                              <div className="img_box">
                                <img src={plan} class="img-fluid" alt="img" />
                              </div>
                              <div>
                                <p className="mb-0">Plan {i + 1}</p>
                                <h5 className="mb-0">
                                  {item.poolAmount / 1e18} USDT
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : i === parseInt(userinfo.userCurrentPlan) + 1 ? (
                          <div className="col-xl-4 d-flex">
                            <div className="box mb-4 current">
                              <div className="img_box">
                                <img src={plan} class="img-fluid" alt="img" />
                              </div>
                              <div>
                                <p className="mb-0">Plan {i + 1}</p>
                                <h5 className="mb-0">
                                  {item.poolAmount / 1e18} USDT
                                </h5>
                                <button
                                  className="claim_btn"
                                  onClick={
                                    progress == "false"
                                      ? () => purchaselevel(i)
                                      : () => empty()
                                  }
                                >
                                  {progress == "false"
                                    ? "Join Now"
                                    : "Processing.."}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-xl-4 d-flex">
                            <div className="box mb-4 current">
                              <div className="img_box">
                                <img src={plan} class="img-fluid" alt="img" />
                              </div>
                              <div>
                                <p className="mb-0">Plan {i + 1}</p>
                                <h5 className="mb-0">
                                  {item.poolAmount / 1e18} USDT
                                </h5>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
                <div className="dashbox mb-4 refferal">
                  <h4 className="h4tag mb-4 pb-4">Referral Team Count</h4>
                  <div className="scroll">
                    <Scrollbars style={{ width: "100%", height: 260 }}>
                      <div className="ref_flx">
                        {referrallist &&
                          referrallist.length > 0 &&
                          referrallist.map((item) => {
                            return (
                              <div className="inbox">
                                <img
                                  src={user}
                                  class="img-fluid userimg"
                                  alt="img"
                                />
                                <div className="id">
                                  <h6>{" " + "ID " + item.id}</h6>
                                </div>
                                <div className="box">
                                  <span>Team Count</span>
                                  <p>{item.teamCount}</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
                <div className="dashbox">
                  <h4 className="h4tag mb-4">Referral History</h4>
                  <div className="history_table">
                    <DataTable
                      columns={columns}
                      data={referrallist}
                      pagination
                      responsive
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade primary_modal"
        id="warningPopup"
        tabIndex={-1}
        aria-labelledby="warning_Popup"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="warning_Popup">
                Warning
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2  d-flex gap-2">
                  Previous incomes are resetted. Are you Sure to continu
                </div>
                <button className="primaty_btn">Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
