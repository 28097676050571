import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="row">
            <div className="col-lg-6">
              <Link to="/" >
              <img src={logo} class="img-fluid mt-auto mb-4" alt="img" />
              </Link>
              {/* <p className ="para" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
            </div>
            <div className="col-lg-6">
              <div className="right_box">
                <p>Follow us</p>
                <ul className="footer_social_links">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-telegram-plane"></i>
                    </a>
                  </li>

                  {/* <li>
                    <a href="#" target="_blank">
                      <i class="fa-brands fa-reddit-alien"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i class="fa-brands fa-x-twitter"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="blw">
          <p>
            Copy right © {new Date().getFullYear()} <a href="#">Zeus</a>, All
            rights Reserved
          </p>
          {/* <ul>
            <li>
              <a href="#">Terms of service </a>{" "}
            </li>
            <li>
              <a href="#"> Privacy Policy</a>{" "}
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
}
