// import axios from 'axios';
import config from "../config/config";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import mlmabi from "../ABI/mlmABI";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext
} from "ethereum-multicall";
import BigNumber from "bignumber.js";
import { toastAlert } from "../helper/toastAlert";
import store from "../store";
import usdtabi from "../ABI/usdtABI";

export const getCurAddr = async () => {
  try {
    var reduxVal = store.getState();
    var transfort =
      reduxVal &&
      reduxVal.walletConnection &&
      reduxVal.walletConnection &&
      reduxVal.walletConnection.web3 &&
      reduxVal.walletConnection.web3.key
        ? reduxVal.walletConnection.web3
        : {};
    console.log(transfort, "===========");
    if (
      transfort &&
      transfort != null &&
      transfort != undefined &&
      transfort != ""
    ) {
      console.log("inside");
      var web3 = new Web3(transfort);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      let bnbbalance = await web3.eth.getBalance(result[0]);
      if (network == config.networkVersion) {
        localStorage.setItem("account", result[0]);
        var mlmContract = new web3.eth.Contract(mlmabi, config.mlmcontract);
        var usdtContract = new web3.eth.Contract(usdtabi, config.usdtcontract);
        let userbalance = await usdtContract.methods
          .balanceOf(result[0])
          .call();
        localStorage.setItem("account", result[0]);
        const multicall = new Multicall({
          web3Instance: web3
        });
        var contractCallContext = [
          {
            reference: "details",
            contractAddress: config.mlmcontract,
            abi: mlmabi,
            calls: [
              {
                reference: "userprofile",
                methodName: "users",
                methodParameters: [result[0]]
              },
              {
                reference: "earnlimit",
                methodName: "checkMaxIcome",
                methodParameters: [result[0]]
              },
              {
                OWNER: "owner",
                methodName: "_owner",
                methodParameters: []
              }
            ]
          }
        ];
        const results = await multicall.call(contractCallContext);
        var userdetails = await getFormatMulticall(results, "userprofile", 0);
        var earn_limit = await getFormatMulticall(results, "earnlimit", 0);
        let userid = await bignumber(userdetails[1]);
        let direct_Income = await bignumber(userdetails[3]);
        let roi_Income = await bignumber(userdetails[4]);
        let level_Income = await bignumber(userdetails[5]);
        let withdraw_levelIncome = await bignumber(userdetails[6]);
        let total_Income = await bignumber(userdetails[7]);
        let total_Withdraw = await bignumber(userdetails[8]);
        let invest_Amount = await bignumber(userdetails[9]);
        let joined_Date = await bignumber(userdetails[10]);
        let lastCliamed_date = await bignumber(userdetails[11]);
        let userCurrent_Plan = await bignumber(userdetails[12]);
        let team_Count = await bignumber(userdetails[13]);
        let earnlimit = await bignumber(earn_limit[0]);
        let data_user = {
          isExist: userdetails[0],
          userid: userid,
          parent: userdetails[2],
          directIncome: direct_Income,
          roiIncome: roi_Income,
          levelIncome: level_Income,
          withdrawlevelIncome: withdraw_levelIncome,
          totalIncome: total_Income,
          totalWithdraw: total_Withdraw,
          investAmount: invest_Amount,
          joinedDate: joined_Date,
          lastCliameddate: lastCliamed_date,
          userCurrentPlan: userCurrent_Plan,
          teamCount: team_Count,
          earnlimit: earnlimit
        };

        var data = {
          provider: transfort,
          address: result[0],
          userdetails: data_user,
          userbalance: userbalance
        };
        console.log("infinal");
        return data;
      } else {
        toastAlert("error", "Please select BSC chain network", "network");
        localStorage.clear();
        // return {
        // }
      }
    } else {
      console.log("inelse");
      // return {

      // }
    }
  } catch (err) {
    console.log(err, "mmmmmmmmmmmmmmmm");
    // return {
    // }
  }
};

function getFormatMulticall(results, name, pos) {
  try {
    var index =
      results &&
      results.results &&
      results.results.details &&
      results.results.details.callsReturnContext &&
      results.results.details.callsReturnContext.findIndex(
        (val) => val.reference == name
      );
    var returnVal =
      results.results.details.callsReturnContext[index] &&
      results.results.details.callsReturnContext[index].returnValues
        ? results.results.details.callsReturnContext[index].returnValues
        : "";
    return returnVal;
  } catch (err) {
    console.log(err, "==+++++++");
    return "";
  }
}

async function bignumber(inputs) {
  let ten = new BigNumber(inputs.hex, 16);
  var value = ten.toString(10);
  return value;
}
