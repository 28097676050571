import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import zeus from "../assets/images/zeus.svg";

import AOS from "aos";
import "aos/dist/aos.css";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      {/* <ScrollToTopOnMount />  */}
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 m-auto">
              <div className="box" data-aos="fade-up" data-aos-duration="1000">
                <h5 className="h5tag mb-4">
                  Empowering Lives and Bridging Success
                </h5>
                <img src={zeus} class="img-fluid mb-4" alt="img" />
                <h5 className="h5tag mb-4">Your Superlative MLM Destiny</h5>
                <p>
                  Experience the convergence of intelligent investments and
                  unparalleled returns. Join us in reshaping success, empowering
                  one investor at a time. Seize the distinctive opportunity to
                  invest and attain unprecedented returns.
                </p>
                {localStorage.getItem("account") &&
                localStorage.getItem("account") != null &&
                localStorage.getItem("account") != undefined &&
                localStorage.getItem("account") != "" ? (
                  ""
                ) : (
                  <button
                    class="primary_btn"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    <span class="button__icon-wrapper">
                      <svg
                        class="button__icon-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M0.886663 8.5533L8.28809 1.15188M8.28809 1.15188H2.73702M8.28809 1.15188V6.70295"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        class="button__icon-svg  button__icon-svg--copy"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        fill="none"
                        viewBox="0 0 14 15"
                      >
                        <path
                          fill="currentColor"
                          d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                        ></path>
                      </svg>
                    </span>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
            <div className="col-lg-8 m-auto text-center d-none d-lg-block">
              <img
                src={require("../assets/images/banimg.png")}
                class="img-fluid ms-5"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="platform">
          <div className="container">
            <div
              className="platform_bg"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 className="h2tag text-center mb-4">Supported Platform</h2>
              <div className="flx">
                <div className="box">
                  <img
                    src={require("../assets/images/p1.png")}
                    class="img-fluid"
                    alt="img"
                  />
                  <p className="mb-0">Apeswap</p>
                </div>
                <div className="box">
                  <img
                    src={require("../assets/images/p2.png")}
                    class="img-fluid"
                    alt="img"
                  />
                  <p className="mb-0">Apeswap</p>
                </div>
                <div className="box">
                  <img
                    src={require("../assets/images/p3.png")}
                    class="img-fluid"
                    alt="img"
                  />
                  <p className="mb-0">Apeswap</p>
                </div>
                <div className="box">
                  <img
                    src={require("../assets/images/p4.png")}
                    class="img-fluid"
                    alt="img"
                  />
                  <p className="mb-0">Apeswap</p>
                </div>
                <div className="box">
                  <img
                    src={require("../assets/images/p5.png")}
                    class="img-fluid"
                    alt="img"
                  />
                  <p className="mb-0">Apeswap</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features">
          <div className="container">
            <div className="features_bg">
              <div className="row">
                <div
                  className="col-lg-4 mb-4 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2 className="h2tag">Imperative Highlights of Zeus MLM </h2>
                  {/* <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p> */}
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div
                      className="col-lg-12 mb-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="box">
                        <div>
                          <h5 className="h5tag">
                            Progressive rewards at every level
                          </h5>
                          <p>
                            Invest in different membership tiers and unlock
                            earnings tailored to each specific level of
                            engagement, ensuring a successive and rewarding
                            journey as you ascend through the levels.
                          </p>
                        </div>
                        <div className="img_box">
                          <img
                            src={require("../assets/images/f1.png")}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 mb-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                    >
                      <div className="box flex-column align-items-start gap-5">
                        <div>
                          <h5 className="h5tag">
                            The Power of Advanced Automation Algorithms
                          </h5>
                          <p>
                            We employ an advanced automation algorithm that
                            swiftly executes operations without any hindrance,
                            ensuring efficient and seamless investment
                            processes.
                          </p>
                        </div>
                        <div className="img_box">
                          <img
                            src={require("../assets/images/f2.png")}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 mb-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="400"
                    >
                      <div className="box flex-column align-items-start gap-5">
                        <div>
                          <h5 className="h5tag">Continuous Income Stream</h5>
                          <p>
                            Gain non-stop earning possibilities with 24/7
                            earning capabilities. Zeus diligently works around
                            the clock, securing consistent profits even when
                            you're not actively engaged on your computer.
                          </p>
                          {/* <p>
                            Set stop-loss and take-profit levels to manage your
                            risk effectively.
                          </p> */}
                        </div>
                        <div className="img_box">
                          <img
                            src={require("../assets/images/f3.png")}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 mb-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="600"
                    >
                      <div className="box">
                        <div>
                          <h5 className="h5tag">
                            Referral Riches awaits you to earn
                          </h5>
                          <p>
                            The more referrals toward the investment plan, the
                            more income and profit you accumulate. It's a
                            win-win – share the wealth and reap the benefits!
                          </p>
                        </div>
                        <div className="img_box">
                          <img
                            src={require("../assets/images/f4.png")}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 text-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/howimg.png")}
                  class="img-fluid"
                  alt="img"
                />
              </div>
              <div className="col-lg-6 m-auto">
                <h2
                  className="h2tag d-inline-block mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  What Sets It in Motion? The Working Mechanism
                </h2>
                {/* <p data-aos="fade-up" data-aos-duration="1000">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  orci metus, mollis et , elit nec vulputate sagittis, mauris ex
                  tristique erat, dictum iaculis mi massa eget tortor.
                </p> */}
                <div
                  class="accordion"
                  id="accordionExample1"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne1">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne1"
                        aria-expanded="true"
                        aria-controls="collapseOne1"
                      >
                        Enrollment and Wallet Integration
                      </button>
                    </h2>
                    <div
                      id="collapseOne1"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne1"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <p>
                          {/* Easily integrate <span>Zeus</span> with popular
                          cryptocurrency exchanges.Your funds remain secure on
                          the exchange while the bot handles the trading. */}
                          Members register and link their wallets within the MLM
                          platform.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo2">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo2"
                        aria-expanded="false"
                        aria-controls="collapseTwo2"
                      >
                        Opt for Investment Packages
                      </button>
                    </h2>
                    <div
                      id="collapseTwo2"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo2"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <p>
                          Select the investment package that aligns with your
                          objectives, offering potential profits and rewards.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree3">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree3"
                        aria-expanded="false"
                        aria-controls="collapseThree3"
                      >
                        Initiate Earning with Zeus
                      </button>
                    </h2>
                    <div
                      id="collapseThree3"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree3"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        <p>
                          {/* Watch as <span> Zeus</span> executes trades based on
                          your predefined strategy.Focus on what matters to you
                          while the bot handles the market intricacies. */}
                          Commence on your earnings journey with Zeus, where you
                          can generate income through direct referrals,
                          level-based earnings, and returns on investment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq" name="faq">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <div className="text-center">
                <h2 className="h2tag d-inline-block mb-4">
                  Frequently Asked Questions
                </h2>
              </div>
            </div>
            <div class="accordion" id="accordionExample">
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Q1. What does Zeus entail?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      Zeus, a multi-level networking program provides effortless
                      earning opportunities for members via Referrals. Earn up
                      to 2X, 3X, and 5X based on the chosen investment plans.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Q2. What is the required minimum amount for users to join
                    Zeus?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      Zeus ensures accessibility for users with a minimum
                      investment requirement starting from $25, providing an
                      inclusive opportunity for participation.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Q3. How secure are my investments, and how are profits
                    retained in Zeus?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      Your investments in Zeus are highly secure, employing
                      advanced security measures to safeguard your funds.
                      Profits are securely retained within the platform,
                      ensuring a reliable and protected investment environment.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    Q4. What is the potential profit from direct referrals?
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      You will receive a 5% Direct Referral Income from the
                      package you've invested in Zeus. The more referrals you
                      bring in, the more direct referral income you can
                      accumulate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
